.nav-link {
  @media screen and (min-width: 1024px) {
    &:hover,
    &.w--open {
      a {
        color: #fff;
      }
    }

    .w--current{
      color: #fff;
      background-color: #000;
      a {
        color: #fff;
      }
    }
  }
}
