// Remove Arrows/Spinners for num input
.key-figures_container---section {
  overflow: hidden;

  .container---key-figures {
    opacity: 0;

    input.input-key-figures {

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &[type=number] {
        -moz-appearance: textfield;
      }
    }

    .w-input:focus,
    .w-select:focus {
      border: none;
    }

    .input-key-figures {
      min-height: 70px;
    }
  }
}
