// css
body,
html {
  overscroll-behavior: none;
  overflow-x: none;
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  text-rendering: geometricPrecision;
}

:root {
  --red: #FF6050;
  --grey: rgba(0, 0, 0, 0.8);
  --greyLight: rgba(0, 0, 0, 0.2);
}

.w-webflow-badge {
  display: none !important;
}

.body---book{
  width: 100%
}

@import "./scss/faq.scss";
@import "./scss/primary-cta.scss";
@import "./scss/redirection-cta.scss";
@import "./scss/marketo.scss";
@import "./scss/calculator.scss";
@import "./scss/calendly.scss";
@import "./scss/videoPopup.scss";
@import "./scss/nav.scss";
@import "./scss/search-input.scss";
@import "./scss/weblfow-properties.scss";
@import "./scss/frise.scss";
@import "./scss/primary-nav.scss";
@import "./scss/intercom.scss";
@import "./scss/marquee.scss";
@import "./scss/cards.scss";
@import "./scss/textImage.scss";
@import "./scss/article.scss";
@import "./scss/gob.scss";
@import "./scss/hero.scss";
@import "./scss/mission.scss";
@import "./scss/ressources.scss";
@import "./scss/customCursor.scss";
@import "./scss/lsiIn.scss";
@import "./scss/defaultAnimation.scss";
@import "./scss/editor.scss";
@import "./scss/e-book.scss";
@import "./scss/apparition.scss";
@import "./scss/horizontal.scss";
@import "./scss/newsletter.scss";
@import "./scss/voeux.scss";
@import "./scss/guide.scss";
@import "./scss/essayer.scss";