*:focus {
  outline: none;
}

.mktoError,
.mktoErrorArrowWrap,
.mktoErrorArrow .mktoErrorMsg {
  all: unset !important;
}

.container-form {
  position: relative;
  width: 600px;
  min-height: 664px;
  height: auto;
  max-width: 100%;

  @media screen and (max-width: 991px) {
    min-height: 545.4px;

    .h-20px---sans-medium{
      font-size: 24px;
    }
  }

  // .w-embed {
  //   display: none;
  // }
}

.mktoForm {
  font-family: futura-pt, sans-serif !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  max-width: 100% !important;
  // margin: 55px auto !important;
  width: 400px !important;

  textarea[rows="2"] {
    height: 1.65em !important;
  }

  a{
    text-decoration: underline;
    display: inline-block;
    padding: 0;
  }

  input::placeholder, textarea::placeholder{
    opacity: 0;
  }

  @media screen and (max-width: 430px) {
    width: 350px !important;
  }

  .mktoButtonRow{
    .mktoButton{
      text-shadow: none!important;
      font-family: futura-pt, sans-serif!important;
      font-size: 16px!important;
      font-weight: 400!important;
    }
  }

  .mktoFormRow {
    width: 100%;

    .mktoFieldDescriptor{
      @media screen and (max-width: 991px) {
        margin-bottom: 0!important;
      }
    }
  }

  a {
    font-size: 13px !important;
    color: rgb(51, 51, 51) !important;
  }
  button.mktoButton[type=submit] {
    margin-top: 20px;
  }
}

.mktoLabel {
  color: var(--grey) !important;
  margin-bottom: 4px !important;
  font-size: 12px !important;
  display: inline-block !important;
  width: auto !important;
  font-weight: 500 !important;
}

.mktoButton {
  background: var(--red) !important;
  padding: 15px 25px !important;
  border-radius: 30px !important;
  border: none !important;
  font-size: 16px !important;
}

.mktoAsterix {
  color: var(--grey) !important;
}

.mktoFieldDescriptor {
  margin-bottom: 0 !important;
}

.mktoField {
  border: none !important;
  border-bottom: 1px solid var(--greyLight) !important;
  padding-bottom: 6px !important;
  margin-bottom: 10px !important;
  width: 100% !important;
  font-size: 20px !important;
  font-weight: 400;
  background: transparent;
  position: relative;
  z-index: 2;

  &:-webkit-autofill {
    -webkit-background-clip: text; // transparent background on autocomplete
  }
}

.mktoFieldWrap,
.mktoField,
.mktoFieldDescriptor {
  width: 100% !important
}

.mktoFieldDescriptor {
  margin-bottom: 20px !important;
}

.mktoErrorArrow {
  background: transparent !important;
  border: none !important;
  height: 1px !important;
}

.mktoError {
  font-size: 12px !important;
  position: absolute !important;
  left: -7px !important;
  bottom: -15px !important;
}

.mktoErrorMsg {
  background: transparent !important;
  border: none !important;
  box-shadow: unset !important;
  text-shadow: unset !important;
  color: var(--red) !important;
  font-size: 12px !important;
  max-width: 100% !important;
  margin-top: 0 !important;

  .mktoErrorDetail {
    display: inline-block !important;
    margin-top: 7px !important;
  }
}

.mktoButtonRow {
  align-self: flex-start !important;
}

.mktoButtonWrap {
  margin-left: 0 !important;
}

.mktoOffset {
  display: none !important;
}

#Tranche_effectif__c {
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
  background: url('third-party/forms/Arrow.png') no-repeat 100% 0;

}

.loader {
  position: absolute;
  left: 44%;
  top: 44%;
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(255, 255, 255, .3);
  border-radius: 50%;
  border-top-color: var(--red);
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}