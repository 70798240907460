#intercom-btn {
  width: 48px;
  height: 48px;
  background: url(/third-party/intercom.svg) no-repeat center center;
  background-size: contain;
  position: fixed;
  bottom: 20px;
  right: 20px;
  opacity: 0;
  transform: scale(0);
  transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor: pointer;
  z-index: 999;

  .loading-intercom {
    position: absolute;
    z-index: 2;
    opacity: 0;
    width: 90%;
    height: 90%;
    z-index: 2;
    background: #4bd1ca;
    border-radius: 100%;
    position: absolute;
    left: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    &.show {
      opacity: 1;
      transform: scale(1);
    }
  }

  &.loaded {
    opacity: 0.8;
    transform: scale(1);
  }
  &:hover {
    opacity: 1;
  }
}
.spin1 {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #4bd1ca;
    animation: spin 0.5s linear infinite;
    pointer-events: none;
    transform: scale(0);
    transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    &.show {
      opacity: 1;
      transform: scale(1);
    }
}

.spin1 {
  border-bottom-color: #fff;
  border-right-color: #fff;
}

@keyframes spin {
  from {transform: rotate(0);}
  to {transform: rotate(360deg);}
}