.slide---push-video {
  // transform: none!important; // to avoid bugs in fixed position
  .top-content-card---push-video {
    cursor: pointer;
  }
  &.active {
    transform: none !important; // to avoid bugs in fixed position
  }
}

.popup-wrapper {
  z-index: 1001 !important; // because navbar has z-index: 1000
  .video {
    inset: 10% 0px 0px 10% !important;
    @media screen and (max-width: 767px) {
      inset: 0px 0px 0px 0px !important;
      max-width: 100% !important;
    }
  }

  .popup-content-wrapper{
    @media screen and (max-width: 767px) {
      max-height: 100%!important;
    }
  }
}

.w-slider-mask {
  z-index: auto !important;
}
