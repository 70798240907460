.hero-top-bot[background-color="yellow"] {
    /* Your styles here */
    background-color: #fd9;
}

.hero-top-bot[background-color="blue"] {
    /* Your styles here */
    background-color: #99E5E5;
}

.hero-top-bot[background-color="black"] {
    /* Your styles here */
    background-color: #000;
}

.hero-top-bot[background-color="grey"] {
    /* Your styles here */
    background-color: #EEEEEE;
}

.hero-top-bot[background-color="purple"] {
    /* Your styles here */
    background-color: #CCCCFF;
}

.hero-top-bot[background-color="red"] {
    /* Your styles here */
    background-color: #FF9999;
}

div[no-border-radius="true"] {
    border-radius: 0;
}