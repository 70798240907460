body, html {
  overscroll-behavior: none;
  overflow-x: none;
  -webkit-user-select: none;
  user-select: none;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  text-rendering: geometricPrecision;
}

:root {
  --red: #ff6050;
  --grey: #000c;
  --greyLight: #0003;
}

.w-webflow-badge {
  display: none !important;
}

.body---book {
  width: 100%;
}

.faq---section .row-faq {
  -webkit-user-select: none;
  user-select: none;
}

.faq---section .row-faq.active .question-faq, .faq---section .row-faq.active .reponse-faq {
  color: #000;
}

.faq---section .row-faq .top-row---faq {
  cursor: pointer;
}

@media screen and (width >= 992px) {
  .faq---section .row-faq:active .question-faq, .faq---section .row-faq:active .reponse-faq, .faq---section .row-faq:hover .question-faq, .faq---section .row-faq:hover .reponse-faq {
    color: #000;
  }
}

@media screen and (width <= 768px) {
  .faq---section .row-faq {
    font-size: 40px;
    transform: translateX(0);
  }
}

.faq---section .row-faq .right-content-row-faq {
  position: relative;
  border-bottom: none !important;
}

.faq---section .row-faq .right-content-row-faq .border-faq {
  transform-origin: 100%;
  background-color: #00000080;
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0;
  transform: scaleX(100%);
}

.faq---section .row-faq .reponse-faq {
  transition: border .25s cubic-bezier(.16, 1, .3, 1);
  border-top: none !important;
}

.faq---section .row-faq.active .reponse-faq {
  border-top: 1px solid #00000080;
}

.faq---section .row-faq.active .num-ro-faq-text {
  color: #000;
  font-size: 90px;
  transform: translateX(70px);
}

@media screen and (width <= 768px) {
  .faq---section .row-faq.active .num-ro-faq-text {
    font-size: 40px;
    transform: translateX(0);
  }
}

.faq---section .rl_faq6_answer {
  height: auto;
}

.faq---section .rl_faq6_icon-wrapper {
  display: none;
}

.faq---section .num-ro-faq-text {
  transition: all .5s cubic-bezier(.16, 1, .3, 1);
}

.faq---section .question-faq, .faq---section .reponse-faq {
  transition: color .5s cubic-bezier(.16, 1, .3, 1);
}

.primary-cta {
  margin-bottom: 30px;
  display: inline-block;
  position: relative;
  overflow: hidden;
  border-radius: 30px !important;
  transition: inherit !important;
}

.primary-cta_circle {
  pointer-events: none;
  background-color: #ff6050;
  border-radius: 1000px;
  width: 0;
  height: 0;
  margin-top: 0;
  margin-left: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.button_text_container {
  z-index: 10000;
  position: relative;
}

.primary-cta-wrapper {
  background-color: #000;
  display: inline-block;
  position: relative;
  overflow: hidden;
  border-radius: 50px !important;
}

.redirection-button {
  position: relative;
  overflow: hidden;
}

.redirection-button:after {
  content: "";
  opacity: 0;
  z-index: 0;
  background-color: #000;
  border-radius: 40px;
  width: 100%;
  height: 100%;
  transition: opacity .4s cubic-bezier(.25, 1, .5, 1), transform .4s cubic-bezier(.25, 1, .5, 1);
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(.7);
}

.redirection-button .small-text---book {
  z-index: 1;
  transition: color .4s cubic-bezier(.25, 1, .5, 1), opacity .4s cubic-bezier(.25, 1, .5, 1);
  position: relative;
}

.redirection-button .icon---button-redirection {
  z-index: 1;
  transition: filter .4s cubic-bezier(.25, 1, .5, 1);
  position: relative;
}

.redirection-button:hover:after {
  opacity: 1;
  transform: scale(1.1);
}

.redirection-button:hover .small-text---book {
  color: #fff;
  opacity: 1;
}

.redirection-button:hover .icon---button-redirection {
  filter: invert();
}

:focus {
  outline: none;
}

.mktoError, .mktoErrorArrowWrap, .mktoErrorArrow .mktoErrorMsg {
  all: unset !important;
}

.container-form {
  width: 600px;
  max-width: 100%;
  height: auto;
  min-height: 664px;
  position: relative;
}

@media screen and (width <= 991px) {
  .container-form {
    min-height: 545.4px;
  }

  .container-form .h-20px---sans-medium {
    font-size: 24px;
  }
}

.mktoForm {
  flex-direction: column !important;
  align-items: center !important;
  width: 400px !important;
  max-width: 100% !important;
  font-family: futura-pt, sans-serif !important;
  display: flex !important;
}

.mktoForm textarea[rows="2"] {
  height: 1.65em !important;
}

.mktoForm a {
  padding: 0;
  text-decoration: underline;
  display: inline-block;
}

.mktoForm input::placeholder, .mktoForm textarea::placeholder {
  opacity: 0;
}

@media screen and (width <= 430px) {
  .mktoForm {
    width: 350px !important;
  }
}

.mktoForm .mktoButtonRow .mktoButton {
  text-shadow: none !important;
  font-family: futura-pt, sans-serif !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}

.mktoForm .mktoFormRow {
  width: 100%;
}

@media screen and (width <= 991px) {
  .mktoForm .mktoFormRow .mktoFieldDescriptor {
    margin-bottom: 0 !important;
  }
}

.mktoForm a {
  color: #333 !important;
  font-size: 13px !important;
}

.mktoForm button.mktoButton[type="submit"] {
  margin-top: 20px;
}

.mktoLabel {
  color: var(--grey) !important;
  width: auto !important;
  margin-bottom: 4px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  display: inline-block !important;
}

.mktoButton {
  background: var(--red) !important;
  border: none !important;
  border-radius: 30px !important;
  padding: 15px 25px !important;
  font-size: 16px !important;
}

.mktoAsterix {
  color: var(--grey) !important;
}

.mktoField {
  z-index: 2;
  background: none;
  font-weight: 400;
  position: relative;
  border: none !important;
  border-bottom: 1px solid var(--greyLight) !important;
  width: 100% !important;
  margin-bottom: 10px !important;
  padding-bottom: 6px !important;
  font-size: 20px !important;
}

.mktoField:-webkit-autofill {
  -webkit-background-clip: text;
}

.mktoFieldWrap, .mktoField, .mktoFieldDescriptor {
  width: 100% !important;
}

.mktoFieldDescriptor {
  margin-bottom: 20px !important;
}

.mktoErrorArrow {
  background: none !important;
  border: none !important;
  height: 1px !important;
}

.mktoError {
  font-size: 12px !important;
  position: absolute !important;
  bottom: -15px !important;
  left: -7px !important;
}

.mktoErrorMsg {
  box-shadow: unset !important;
  text-shadow: unset !important;
  color: var(--red) !important;
  background: none !important;
  border: none !important;
  max-width: 100% !important;
  margin-top: 0 !important;
  font-size: 12px !important;
}

.mktoErrorMsg .mktoErrorDetail {
  margin-top: 7px !important;
  display: inline-block !important;
}

.mktoButtonRow {
  align-self: flex-start !important;
}

.mktoButtonWrap {
  margin-left: 0 !important;
}

.mktoOffset {
  display: none !important;
}

#Tranche_effectif__c {
  appearance: none;
  background: url("Arrow.67bd5d10.png") 100% 0 no-repeat;
}

.loader {
  border: 3px solid #ffffff4d;
  border-top-color: var(--red);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: 1s ease-in-out infinite spin;
  display: inline-block;
  position: absolute;
  top: 44%;
  left: 44%;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.key-figures_container---section {
  overflow: hidden;
}

.key-figures_container---section .container---key-figures {
  opacity: 0;
}

.key-figures_container---section .container---key-figures input.input-key-figures::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.key-figures_container---section .container---key-figures input.input-key-figures::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.key-figures_container---section .container---key-figures input.input-key-figures[type="number"] {
  -moz-appearance: textfield;
}

.key-figures_container---section .container---key-figures .w-input:focus, .key-figures_container---section .container---key-figures .w-select:focus {
  border: none;
}

.key-figures_container---section .container---key-figures .input-key-figures {
  min-height: 70px;
}

.div-thank-you {
  min-height: 1060px;
  padding-top: 160px;
  position: relative;
  margin-top: 0 !important;
}

.div-thank-you .columns-calendly {
  justify-content: center;
}

.div-thank-you .calendly-1, .div-thank-you .calendly-2 {
  min-height: 620px;
  margin-top: -1px;
}

.div-thank-you .calendly-1 .calendly-inline-widget, .div-thank-you .calendly-2 .calendly-inline-widget {
  display: none;
}

.div-thank-you .loader {
  bottom: 0;
  left: 47%;
}

.slide---push-video .top-content-card---push-video {
  cursor: pointer;
}

.slide---push-video.active {
  transform: none !important;
}

.popup-wrapper {
  z-index: 1001 !important;
}

.popup-wrapper .video {
  inset: 10% 0 0 10% !important;
}

@media screen and (width <= 767px) {
  .popup-wrapper .video {
    max-width: 100% !important;
    inset: 0 !important;
  }

  .popup-wrapper .popup-content-wrapper {
    max-height: 100% !important;
  }
}

.w-slider-mask {
  z-index: auto !important;
}

@media screen and (width <= 991px) {
  .nav-menu-2 {
    padding-bottom: 100px;
  }

  .nav-menu-2 .nav-link.w-dropdown-toggle {
    pointer-events: none;
  }

  .nav-menu-2 .nav-link.w-dropdown-toggle a {
    pointer-events: auto;
  }
}

.nav-menu-2 .nav-link.w-dropdown-toggle.w--current {
  color: #fff;
  background-color: #000;
}

@media screen and (width <= 478px) {
  .nav-menu-2 .nav-link.w-dropdown-toggle.w--current {
    border-radius: 0;
  }
}

.nav-menu-2 .nav-link.w-dropdown-toggle.w--current a {
  color: #fff;
}

.nav-link.btn-nav {
  height: fit-content;
  margin: 0;
}

.navbar-3 .primary-cta-wrapper {
  height: fit-content;
}

.navbar-3 .div-block-38 {
  align-items: center;
}

.search-input {
  outline: none;
  transition: background-color .4s cubic-bezier(.25, 1, .5, 1);
}

.search-input ~ img {
  transition: filter .4s cubic-bezier(.25, 1, .5, 1);
}

.search-input:focus {
  background-color: #000c;
  border-color: #0000;
}

.search-input:focus ~ img {
  filter: invert();
}

.hero-top-bot[background-color="yellow"] {
  background-color: #fd9;
}

.hero-top-bot[background-color="blue"] {
  background-color: #99e5e5;
}

.hero-top-bot[background-color="black"] {
  background-color: #000;
}

.hero-top-bot[background-color="grey"] {
  background-color: #eee;
}

.hero-top-bot[background-color="purple"] {
  background-color: #ccf;
}

.hero-top-bot[background-color="red"] {
  background-color: #f99;
}

div[no-border-radius="true"] {
  border-radius: 0;
}

@media screen and (width <= 768px) {
  .frise {
    -webkit-user-select: none;
    user-select: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow-x: scroll;
  }

  .frise::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (width >= 1024px) {
  .nav-link:hover a, .nav-link.w--open a {
    color: #fff;
  }

  .nav-link .w--current {
    color: #fff;
    background-color: #000;
  }

  .nav-link .w--current a {
    color: #fff;
  }
}

#intercom-btn {
  opacity: 0;
  cursor: pointer;
  z-index: 999;
  background: url("intercom.3f9ce067.svg") center / contain no-repeat;
  width: 48px;
  height: 48px;
  transition: all .5s cubic-bezier(.175, .885, .32, 1.275);
  position: fixed;
  bottom: 20px;
  right: 20px;
  transform: scale(0);
}

#intercom-btn .loading-intercom {
  z-index: 2;
  opacity: 0;
  z-index: 2;
  background: #4bd1ca;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 90%;
  display: flex;
  position: absolute;
  left: 5%;
}

#intercom-btn .loading-intercom.show {
  opacity: 1;
  transform: scale(1);
}

#intercom-btn.loaded {
  opacity: .8;
  transform: scale(1);
}

#intercom-btn:hover {
  opacity: 1;
}

.spin1 {
  pointer-events: none;
  border: 2px solid #4bd1ca;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  transition: all .5s cubic-bezier(.175, .885, .32, 1.275);
  animation: .5s linear infinite spin;
  transform: scale(0);
}

.spin1.show {
  opacity: 1;
  transform: scale(1);
}

.spin1 {
  border-bottom-color: #fff;
  border-right-color: #fff;
}

.marquee {
  width: 4600px;
}

.wrapper---hero-price {
  overflow: visible !important;
}

.link-block-22 .ressource-card-2, .link-block-card .card---e-book-2, .link-block-23 .ressource-card-3, .link-block-card .card-latest-article, .card---e-book, .master-card, .ressource-card-2, .w-dyn-item .w-layout-grid.grid-13, .card---e-book-2, .card-blog, .ressource-card-3, .card-blog, .hero-success-story-premiere-page, .header-blog-section-3 .w-dyn-item .w-layout-grid, .big-header-contenu {
  transition: all .3s ease-in-out;
}

.link-block-22 .ressource-card-2:hover, .link-block-card .card---e-book-2:hover, .link-block-23 .ressource-card-3:hover, .link-block-card .card-latest-article:hover, .card---e-book:hover, .master-card:hover, .ressource-card-2:hover, .w-dyn-item .w-layout-grid.grid-13:hover, .card---e-book-2:hover, .card-blog:hover, .ressource-card-3:hover, .card-blog:hover, .hero-success-story-premiere-page:hover, .header-blog-section-3 .w-dyn-item .w-layout-grid:hover, .big-header-contenu:hover {
  transition: all .3s ease-in-out;
  transform: scale(.98);
  box-shadow: 0 4px 16px #00000026;
}

.card-latest-article img {
  height: 159.2px;
}

.wrapper-articles-3 a {
  color: #333;
  display: unset;
  cursor: pointer;
  font-size: 20px;
  font-weight: 300;
  text-decoration: underline;
}

#w-node-_6390e799-996c-456a-04a4-049fb7e276fb-b7e276f1 .container-form a {
  display: unset;
  cursor: pointer;
  font-size: 14px;
  font-weight: 300;
  text-decoration: underline;
  color: #333 !important;
}

#w-node-_6390e799-996c-456a-04a4-049fb7e276fb-b7e276f1 .container-form a:hover {
  text-decoration: none;
}

.wrapper-articles .wrapper-table, .wrapper-articles-2 .wrapper-table, .wrapper-articles-3 .wrapper-table {
  align-self: start;
  max-width: 300px;
  padding-top: 80px;
  top: 0;
}

@media screen and (width <= 767px) {
  .wrapper-articles .wrapper-table, .wrapper-articles-2 .wrapper-table, .wrapper-articles-3 .wrapper-table {
    z-index: 3;
    pointer-events: none;
    align-items: center;
    width: 90vw;
    max-width: 100%;
    margin: -80vh auto 0;
    padding-top: 80vh;
    display: flex;
    top: 75vh;
    position: sticky !important;
  }
}

@media screen and (width <= 767px) and (width <= 380px) {
  .wrapper-articles .wrapper-table, .wrapper-articles-2 .wrapper-table, .wrapper-articles-3 .wrapper-table {
    padding-top: 70vh;
  }
}

@media screen and (width <= 767px) {
  .wrapper-articles .wrapper-table .tag-table, .wrapper-articles-2 .wrapper-table .tag-table, .wrapper-articles-3 .wrapper-table .tag-table {
    padding: 10px 20px;
    display: none;
    justify-content: flex-start !important;
    width: 400px !important;
  }

  .wrapper-articles .wrapper-table .tag-table .label-table, .wrapper-articles-2 .wrapper-table .tag-table .label-table, .wrapper-articles-3 .wrapper-table .tag-table .label-table {
    max-width: 94%;
  }

  .wrapper-articles .wrapper-table .tag-table.active, .wrapper-articles-2 .wrapper-table .tag-table.active, .wrapper-articles-3 .wrapper-table .tag-table.active {
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
    position: relative;
    pointer-events: auto !important;
    color: #fff !important;
    background-color: #000 !important;
  }
}

@media screen and (width <= 767px) and (width <= 767px) {
  .wrapper-articles .wrapper-table .tag-table.active:after, .wrapper-articles-2 .wrapper-table .tag-table.active:after, .wrapper-articles-3 .wrapper-table .tag-table.active:after {
    content: url("arrows.526291f8.svg");
    margin-top: 5px;
    position: absolute;
    right: 20px;
  }
}

.wrapper-articles .wrapper-table-menu, .wrapper-articles-2 .wrapper-table-menu, .wrapper-articles-3 .wrapper-table-menu {
  opacity: 0;
  pointer-events: none;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  margin-top: -100vh;
  transition: all .2s ease-in, transform .2s ease-in;
  display: flex;
  position: sticky;
  top: 0;
  transform: translateY(10px);
}

.wrapper-articles .wrapper-table-menu .active, .wrapper-articles-2 .wrapper-table-menu .active, .wrapper-articles-3 .wrapper-table-menu .active {
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
  color: #fff !important;
  background-color: #000 !important;
}

@media screen and (width <= 767px) {
  .wrapper-articles .wrapper-table-menu .wrapper-table-menu-container, .wrapper-articles-2 .wrapper-table-menu .wrapper-table-menu-container, .wrapper-articles-3 .wrapper-table-menu .wrapper-table-menu-container {
    background-color: #000;
    border-radius: 20px;
    width: 90vw;
    padding: 15px;
    position: absolute;
    bottom: 10vh;
  }

  .wrapper-articles .wrapper-table-menu .wrapper-table-menu-container a, .wrapper-articles-2 .wrapper-table-menu .wrapper-table-menu-container a, .wrapper-articles-3 .wrapper-table-menu .wrapper-table-menu-container a {
    color: #696969;
  }

  .wrapper-articles .wrapper-table-menu .wrapper-table-menu-item, .wrapper-articles-2 .wrapper-table-menu .wrapper-table-menu-item, .wrapper-articles-3 .wrapper-table-menu .wrapper-table-menu-item {
    color: #000;
    padding: 6px 0;
    position: relative;
  }

  .wrapper-articles .wrapper-table-menu .wrapper-table-menu-item:not(:last-child), .wrapper-articles-2 .wrapper-table-menu .wrapper-table-menu-item:not(:last-child), .wrapper-articles-3 .wrapper-table-menu .wrapper-table-menu-item:not(:last-child) {
    border-bottom: 1px solid #000;
  }

  .wrapper-articles .wrapper-table-menu.show, .wrapper-articles-2 .wrapper-table-menu.show, .wrapper-articles-3 .wrapper-table-menu.show {
    z-index: 5;
    opacity: 1;
    pointer-events: all;
    transition: all .2s ease-in, transform .2s ease-in;
    transform: translateY(0);
  }

  .wrapper-articles .wrapper-table.hide, .wrapper-articles-2 .wrapper-table.hide, .wrapper-articles-3 .wrapper-table.hide {
    opacity: 0;
    transition: all .2s ease-in, transform .2s ease-in;
    transform: translateY(0);
  }
}

.wrapper-articles .container-right-article .article-chapo, .wrapper-articles .article-container-760 .article-chapo, .wrapper-articles-2 .container-right-article .article-chapo, .wrapper-articles-2 .article-container-760 .article-chapo, .wrapper-articles-3 .container-right-article .article-chapo, .wrapper-articles-3 .article-container-760 .article-chapo {
  margin-bottom: 50px;
}

.wrapper-articles .container-right-article .article-right-content, .wrapper-articles .article-container-760 .article-right-content, .wrapper-articles-2 .container-right-article .article-right-content, .wrapper-articles-2 .article-container-760 .article-right-content, .wrapper-articles-3 .container-right-article .article-right-content, .wrapper-articles-3 .article-container-760 .article-right-content {
  grid-row-gap: 0;
}

.wrapper-articles .container-right-article .article-right-content img, .wrapper-articles .article-container-760 .article-right-content img, .wrapper-articles-2 .container-right-article .article-right-content img, .wrapper-articles-2 .article-container-760 .article-right-content img, .wrapper-articles-3 .container-right-article .article-right-content img, .wrapper-articles-3 .article-container-760 .article-right-content img {
  width: auto !important;
  height: auto !important;
}

.wrapper-articles .container-right-article .article-right-content a, .wrapper-articles .article-container-760 .article-right-content a, .wrapper-articles-2 .container-right-article .article-right-content a, .wrapper-articles-2 .article-container-760 .article-right-content a, .wrapper-articles-3 .container-right-article .article-right-content a, .wrapper-articles-3 .article-container-760 .article-right-content a {
  color: #333;
  font-size: 20px;
  text-decoration: underline;
  display: inline;
}

.wrapper-articles .container-right-article .w-richtext figure, .wrapper-articles .article-container-760 .w-richtext figure, .wrapper-articles-2 .container-right-article .w-richtext figure, .wrapper-articles-2 .article-container-760 .w-richtext figure, .wrapper-articles-3 .container-right-article .w-richtext figure, .wrapper-articles-3 .article-container-760 .w-richtext figure {
  max-width: 100%;
  margin: 50px auto;
}

.wrapper-articles .container-right-article h2, .wrapper-articles .article-container-760 h2, .wrapper-articles-2 .container-right-article h2, .wrapper-articles-2 .article-container-760 h2, .wrapper-articles-3 .container-right-article h2, .wrapper-articles-3 .article-container-760 h2 {
  padding: 30px 0;
  font-size: 40px;
  font-weight: 450;
  line-height: 40px;
}

.wrapper-articles .container-right-article h1, .wrapper-articles .article-container-760 h1, .wrapper-articles-2 .container-right-article h1, .wrapper-articles-2 .article-container-760 h1, .wrapper-articles-3 .container-right-article h1, .wrapper-articles-3 .article-container-760 h1 {
  margin: 40px 0;
}

.wrapper-articles .container-right-article h3, .wrapper-articles .article-container-760 h3, .wrapper-articles-2 .container-right-article h3, .wrapper-articles-2 .article-container-760 h3, .wrapper-articles-3 .container-right-article h3, .wrapper-articles-3 .article-container-760 h3 {
  margin-bottom: 30px;
  font-size: 28px;
  font-weight: 450;
  line-height: 30px;
}

.wrapper-articles .container-right-article p, .wrapper-articles .article-container-760 p, .wrapper-articles-2 .container-right-article p, .wrapper-articles-2 .article-container-760 p, .wrapper-articles-3 .container-right-article p, .wrapper-articles-3 .article-container-760 p {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
}

.wrapper-articles .container-right-article strong, .wrapper-articles .article-container-760 strong, .wrapper-articles-2 .container-right-article strong, .wrapper-articles-2 .article-container-760 strong, .wrapper-articles-3 .container-right-article strong, .wrapper-articles-3 .article-container-760 strong {
  font-weight: 450;
}

.wrapper-articles .container-right-article ol.list-container, .wrapper-articles .article-container-760 ol.list-container, .wrapper-articles-2 .container-right-article ol.list-container, .wrapper-articles-2 .article-container-760 ol.list-container, .wrapper-articles-3 .container-right-article ol.list-container, .wrapper-articles-3 .article-container-760 ol.list-container {
  margin: 50px 0;
  padding: 0;
  list-style: none;
}

.wrapper-articles .container-right-article ol.list-container .list, .wrapper-articles .article-container-760 ol.list-container .list, .wrapper-articles-2 .container-right-article ol.list-container .list, .wrapper-articles-2 .article-container-760 ol.list-container .list, .wrapper-articles-3 .container-right-article ol.list-container .list, .wrapper-articles-3 .article-container-760 ol.list-container .list {
  border-top: 1px solid #ccc;
  justify-content: space-between;
  padding-top: 30px;
  display: flex;
}

.wrapper-articles .container-right-article ol.list-container .list .list-number, .wrapper-articles .article-container-760 ol.list-container .list .list-number, .wrapper-articles-2 .container-right-article ol.list-container .list .list-number, .wrapper-articles-2 .article-container-760 ol.list-container .list .list-number, .wrapper-articles-3 .container-right-article ol.list-container .list .list-number, .wrapper-articles-3 .article-container-760 ol.list-container .list .list-number {
  color: #fff;
  background-color: #000;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  display: flex;
}

.wrapper-articles .container-right-article ol.list-container .list .list-content, .wrapper-articles .article-container-760 ol.list-container .list .list-content, .wrapper-articles-2 .container-right-article ol.list-container .list .list-content, .wrapper-articles-2 .article-container-760 ol.list-container .list .list-content, .wrapper-articles-3 .container-right-article ol.list-container .list .list-content, .wrapper-articles-3 .article-container-760 ol.list-container .list .list-content {
  width: 88%;
}

.wrapper-articles .container-right-article ol.list-container .list .list-content .list-title, .wrapper-articles .article-container-760 ol.list-container .list .list-content .list-title, .wrapper-articles-2 .container-right-article ol.list-container .list .list-content .list-title, .wrapper-articles-2 .article-container-760 ol.list-container .list .list-content .list-title, .wrapper-articles-3 .container-right-article ol.list-container .list .list-content .list-title, .wrapper-articles-3 .article-container-760 ol.list-container .list .list-content .list-title {
  margin-top: 2px;
  font-size: 20px;
  line-height: 25px;
}

.wrapper-articles .container-right-article ol.list-container .list .list-content em, .wrapper-articles .article-container-760 ol.list-container .list .list-content em, .wrapper-articles-2 .container-right-article ol.list-container .list .list-content em, .wrapper-articles-2 .article-container-760 ol.list-container .list .list-content em, .wrapper-articles-3 .container-right-article ol.list-container .list .list-content em, .wrapper-articles-3 .article-container-760 ol.list-container .list .list-content em {
  font-style: normal;
  font-weight: 450;
}

.wrapper-articles .container-right-article ol.list-container .list .list-content p, .wrapper-articles .article-container-760 ol.list-container .list .list-content p, .wrapper-articles-2 .container-right-article ol.list-container .list .list-content p, .wrapper-articles-2 .article-container-760 ol.list-container .list .list-content p, .wrapper-articles-3 .container-right-article ol.list-container .list .list-content p, .wrapper-articles-3 .article-container-760 ol.list-container .list .list-content p {
  margin-bottom: 20px;
}

.wrapper-articles .container-right-article .check-list-container, .wrapper-articles .article-container-760 .check-list-container, .wrapper-articles-2 .container-right-article .check-list-container, .wrapper-articles-2 .article-container-760 .check-list-container, .wrapper-articles-3 .container-right-article .check-list-container, .wrapper-articles-3 .article-container-760 .check-list-container {
  flex-direction: column;
  padding-left: 0;
  line-height: 30px;
  display: flex;
}

.wrapper-articles .container-right-article .check-list-container .check-list-item, .wrapper-articles .article-container-760 .check-list-container .check-list-item, .wrapper-articles-2 .container-right-article .check-list-container .check-list-item, .wrapper-articles-2 .article-container-760 .check-list-container .check-list-item, .wrapper-articles-3 .container-right-article .check-list-container .check-list-item, .wrapper-articles-3 .article-container-760 .check-list-container .check-list-item {
  justify-content: space-between;
  gap: 30px;
  margin: 25px 0;
  display: flex;
}

.wrapper-articles .container-right-article .check-list-container .check-list-item .check-list-icon:before, .wrapper-articles .article-container-760 .check-list-container .check-list-item .check-list-icon:before, .wrapper-articles-2 .container-right-article .check-list-container .check-list-item .check-list-icon:before, .wrapper-articles-2 .article-container-760 .check-list-container .check-list-item .check-list-icon:before, .wrapper-articles-3 .container-right-article .check-list-container .check-list-item .check-list-icon:before, .wrapper-articles-3 .article-container-760 .check-list-container .check-list-item .check-list-icon:before {
  content: url("check.4fc89a07.svg");
  width: 30px;
  height: 30px;
  display: inline-block;
}

.wrapper-articles .container-right-article .check-list-container .check-list-item .check-list-content, .wrapper-articles .article-container-760 .check-list-container .check-list-item .check-list-content, .wrapper-articles-2 .container-right-article .check-list-container .check-list-item .check-list-content, .wrapper-articles-2 .article-container-760 .check-list-container .check-list-item .check-list-content, .wrapper-articles-3 .container-right-article .check-list-container .check-list-item .check-list-content, .wrapper-articles-3 .article-container-760 .check-list-container .check-list-item .check-list-content {
  width: 90%;
  margin-top: 3px;
  padding-left: 0;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}

.wrapper-articles .container-right-article blockquote, .wrapper-articles .article-container-760 blockquote, .wrapper-articles-2 .container-right-article blockquote, .wrapper-articles-2 .article-container-760 blockquote, .wrapper-articles-3 .container-right-article blockquote, .wrapper-articles-3 .article-container-760 blockquote {
  border: 1px solid #0003;
  border-radius: 20px;
  padding: 10px;
  font-size: 20px;
  display: block;
}

.wrapper-articles .container-right-article blockquote:before, .wrapper-articles .article-container-760 blockquote:before, .wrapper-articles-2 .container-right-article blockquote:before, .wrapper-articles-2 .article-container-760 blockquote:before, .wrapper-articles-3 .container-right-article blockquote:before, .wrapper-articles-3 .article-container-760 blockquote:before {
  content: url("Quote.423a5814.svg");
  width: 100%;
  height: 30px;
  display: inline-block;
}

.wrapper-articles a, .wrapper-articles-2 a, .wrapper-articles-3 a {
  transition: text-decoration-color .2s ease-in-out;
}

.wrapper-articles a:hover, .wrapper-articles-2 a:hover, .wrapper-articles-3 a:hover {
  text-decoration-color: #0000;
  transition: text-decoration-color .2s ease-in-out;
}

.wrapper-articles .article-right-content:not(.e-book-align) h2, .wrapper-articles-2 .article-right-content:not(.e-book-align) h2, .wrapper-articles-3 .article-right-content:not(.e-book-align) h2 {
  transform: translateY(-55px);
  padding: 70px 0 0 !important;
}

.wrapper-articles blockquote, .wrapper-articles-2 blockquote, .wrapper-articles-3 blockquote {
  background: #ccccfe;
  border: 1px;
  border-radius: 20px;
  margin-bottom: 30px;
  font-size: 20px;
  line-height: 30px;
  display: block;
  padding: 20px 20px 30px !important;
}

.wrapper-articles p, .wrapper-articles-2 p, .wrapper-articles-3 p {
  margin-bottom: 32px !important;
  line-height: 30px !important;
}

.wrapper-articles h2, .wrapper-articles-2 h2, .wrapper-articles-3 h2 {
  font-size: 46px !important;
  line-height: 46px !important;
}

.wrapper-articles a img, .wrapper-articles-2 a img, .wrapper-articles-3 a img {
  transition: all .3s ease-in-out;
}

.wrapper-articles a img:hover, .wrapper-articles-2 a img:hover, .wrapper-articles-3 a img:hover {
  filter: drop-shadow(0 4px 16px #00000026);
  transition: all .3s ease-in-out;
  transform: scale(.98);
}

.bloc-corail {
  background: #f99;
  border: 1px;
  border-radius: 20px;
  font-size: 20px;
  line-height: 30px;
  display: block;
  margin-bottom: 32px !important;
  padding: 20px !important;
}

.bloc-corail p {
  margin-bottom: 0 !important;
}

.bloc-corail p strong, .bloc-corail p b {
  font-weight: 500;
}

.bloc-mauve {
  background: #ccccfe;
  border: 1px;
  border-radius: 20px;
  font-size: 20px;
  line-height: 30px;
  display: block;
  margin-bottom: 32px !important;
  padding: 20px !important;
}

.bloc-mauve p {
  margin-bottom: 0 !important;
}

.bloc-mauve p strong, .bloc-mauve p b {
  font-weight: 500;
}

.bloc-vert {
  background: #9db;
  border: 1px;
  border-radius: 20px;
  font-size: 20px;
  line-height: 30px;
  display: block;
  margin-bottom: 32px !important;
  padding: 20px !important;
}

.bloc-vert p {
  margin-bottom: 0 !important;
}

.bloc-vert p strong, .bloc-vert p b {
  font-weight: 500;
}

.bloc-turquoise {
  background: #99e5e5;
  border: 1px;
  border-radius: 20px;
  font-size: 20px;
  line-height: 30px;
  display: block;
  margin-bottom: 32px !important;
  padding: 20px !important;
}

.bloc-turquoise p {
  margin-bottom: 0 !important;
}

.bloc-turquoise p strong, .bloc-turquoise p b {
  font-weight: 500;
}

.bloc-jaune {
  background: #fd9;
  border: 1px;
  border-radius: 20px;
  font-size: 20px;
  line-height: 30px;
  display: block;
  margin-bottom: 32px !important;
  padding: 20px !important;
}

.bloc-jaune p {
  margin-bottom: 0 !important;
}

.bloc-jaune p strong, .bloc-jaune p b {
  font-weight: 500;
}

.wrapper-articles-2 .article-right-content:not(.e-book-align) h4, .wrapper-articles .article-right-content:not(.e-book-align) h4, .wrapper-articles-2 .article-right-content:not(.e-book-align) h3, .wrapper-articles .article-right-content:not(.e-book-align) h3 {
  margin: 10px 0 30px;
  font-family: Libre Baskerville, sans-serif;
  font-weight: 500;
}

.wrapper-articles-2 .article-right-content:not(.e-book-align) h2, .wrapper-articles .article-right-content:not(.e-book-align) h2 {
  margin: 50px 0 28px;
  font-family: Libre Baskerville, sans-serif;
  transform: none;
  padding: 0 !important;
  font-size: 36px !important;
}

.wrapper-articles-2 .article-right-content:not(.e-book-align) h5, .wrapper-articles .article-right-content:not(.e-book-align) h5 {
  margin: 10px 0 30px;
  font-family: Libre Baskerville, sans-serif;
  font-weight: 500;
}

table {
  border-collapse: separate;
  table-layout: fixed;
  min-width: 100%;
  margin: 0 auto;
  padding: 0;
}

table th {
  text-align: center;
  color: #fff;
  text-align: center;
  background: #000;
  border: 1px solid #000;
  padding: 8px;
  font-weight: bold;
}

table th:first-child {
  border-top-left-radius: 25px;
}

table th:last-child {
  border-top-right-radius: 25px;
}

table td {
  border: 1px solid #000;
  padding: 8px;
  transition: all .1s;
}

table td:hover {
  background: #fd9;
  transition: all .1s;
}

table tr {
  color: #000;
  text-align: left;
  background-color: #f5f5f5;
}

table .mobile-head {
  display: none;
}

table tr:last-child td:first-child {
  border-bottom-left-radius: 25px;
}

table tr:last-child td:last-child {
  border-bottom-right-radius: 25px;
}

@media screen and (width <= 600px) {
  table {
    border-collapse: collapse;
    table-layout: fixed;
    border: 1px solid #000;
    min-width: 100%;
    margin: 0 auto;
    padding: 0;
  }

  table td {
    text-align: right;
    width: 100% \9 ;
    float: left\9 ;
    border: 1px solid #e6e5e5;
    padding: 8px;
    display: block;
  }

  table tr {
    color: #000;
    text-align: right;
    background-color: #ddd;
    margin: 8px;
  }

  table tr:first-child {
    display: none;
  }

  table tr {
    display: block;
  }

  table td:not(:first-child) {
    border-top: 0;
  }

  table .mobile-head {
    color: #000;
    float: left;
    text-align: left;
    font-weight: bold;
    display: block;
  }
}

.wrapper-articles-2 .container-right-article .w-richtext figure, .wrapper-articles .container-right-article .w-richtext figure {
  margin: 0 auto 25px !important;
}

.w-code-block {
  border-radius: 25px;
  -webkit-user-select: text !important;
  user-select: text !important;
  -moz-user-select: -moz-text !important;
}

@media screen and (width <= 479px) {
  .w-code-block {
    margin: 0 auto;
    max-width: 350px !important;
  }

  body {
    overflow-wrap: anywhere;
  }
}

.wrapper-articles-2 .article-right-content .sources h2, .wrapper-articles .article-right-content .sources h2 {
  font-size: 18px !important;
}

.sources p {
  margin-bottom: 10px !important;
  font-size: 14px !important;
  line-height: 18px !important;
}

.gob {
  position: relative;
}

.gob .left_col---gob {
  z-index: 1;
  position: relative;
}

.gob---section {
  position: relative;
}

.gob:nth-of-type(2) {
  position: absolute;
  top: 0;
}

@media screen and (width <= 479px) {
  .hero-top-bot .box-image-content-hero-tb img {
    max-width: 100% !important;
  }
}

.hero-top-bot .image-61 {
  bottom: -175px;
}

.mission---section .h-30px---serif, .mission---section .row---mission, .mission---section .small-text---book, .mission---section .bot-content---mission {
  opacity: 0;
}

.re .w-tab-content {
  overflow: visible;
}

.search-input-2:-webkit-autofill {
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: inset 0 0 0 30px #000 !important;
}

.custom-cursor {
  background-color: var(--red);
  z-index: 9999;
  pointer-events: none;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.custom-cursor:after {
  content: "Drag";
  color: #fff;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-weight: 450;
  display: flex;
}

.lsi-in---section.appear {
  z-index: auto;
}

.lsi-in---section.appear + .etapes---section {
  position: relative;
}

.w-editor-edit-fade-in .container---1200px .wrapper-texte---text-image .h-30px---serif, .w-editor-edit-fade-in .container---1200px .wrapper-texte---text-image .primary-cta-wrapper, .w-editor-edit-fade-in .container---1200px .wrapper-texte---text-image .w-richtext, .w-editor-edit-fade-in .container---1200px .wrapper-texte---text-image .body---book, .w-editor-edit-fade-in .container---1200px .wrapper-texte---text-image a, .w-editor-edit-fade-in .container---1200px .content-image---text-image img {
  opacity: 1;
}

.w-editor-edit-fade-in .hero---middle-content .container-anim---header {
  transform: rotate(-10deg);
}

.w-editor-edit-fade-in .hero---middle-content .container-anim---header .wrapper-hero img, .w-editor-edit-fade-in .hero---middle-content .container-anim---header-sketch, .w-editor-edit-fade-in .hero-team---section .h-36px---serif, .w-editor-edit-fade-in .hero-team---section .body---book, .w-editor-edit-fade-in .hero-team---section .wrapper-image---hero-team, .w-editor-edit-fade-in .hero---section .title---hero, .w-editor-edit-fade-in .hero---section .body---book, .w-editor-edit-fade-in .hero---section .hero---bottom-content a, .w-editor-edit-fade-in .hero-top-bot .h-30px---serif, .w-editor-edit-fade-in .hero-top-bot .image-tb, .w-editor-edit-fade-in .hero-top-bot .body---book {
  opacity: 1;
}

.w-editor-edit-fade-in .card---price-hero {
  opacity: 1;
  transform: translateY(0);
}

.w-editor-edit-fade-in .hero-price---section .h-30px---serif {
  opacity: 1;
}

.article-container-760 h2 {
  margin-bottom: 60px;
  padding: 0 !important;
}

.article-container-760 h3 {
  margin: 50px 0 !important;
}

.hero---top-content .title---hero, .hero---top-content h1.h-30px---serif, .hero-top-bot .title---hero, .hero-top-bot h1.h-30px---serif {
  opacity: 0;
}

.hero---top-content .container-body---hero, .hero-top-bot .container-body---hero {
  opacity: 0;
  animation: .7s .5s forwards fadeIn;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.hero---middle-content .container-anim---header {
  transform: rotate(0);
}

.hero---middle-content .container-anim---header .wrapper-hero img, .hero---middle-content .container-anim---header-sketch {
  opacity: 0;
}

[hero-mode~="true"] .wrapper-texte---text-image {
  opacity: 0;
  animation: .8s cubic-bezier(.68, -.55, .27, 1.55) .3s forwards fade-in;
  transform: translate(5px, 10px);
}

[hero-mode~="true"] .h-30px---serif {
  opacity: 0;
  animation: .8s cubic-bezier(.68, -.55, .27, 1.55) .4s forwards fade-in;
}

[hero-mode~="true"] .w-richtext, [hero-mode~="true"] .body---book {
  opacity: 0;
  animation: .8s cubic-bezier(.68, -.55, .27, 1.55) .45s forwards fade-in-staggered;
}

[hero-mode~="true"] .content-image---text-image img, [hero-mode~="true"] .div-block-44 {
  opacity: 0;
  animation: .8s cubic-bezier(.68, -.55, .27, 1.55) .3s forwards fade-in;
}

[hero-mode~="true"] .primary-cta-wrapper, [hero-mode~="true"] a {
  opacity: 0;
  animation: .8s cubic-bezier(.68, -.55, .27, 1.55) .2s forwards fade-in;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translate(5px, 10px);
  }

  to {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes fade-in-staggered {
  from {
    opacity: 0;
    transform: translate(5px, 10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.hero-top-bot .h-30px---serif, .hero-top-bot .image-tb, .hero-top-bot .body---book {
  opacity: 0;
}

.horizontal-container {
  z-index: 999;
  color: #fff;
  text-align: center;
  background-color: #000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100dvw;
  height: 100dvh;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.horizontal-container-title {
  margin-bottom: 24px;
  font-size: 45px;
}

@media screen and (width <= 900px) and (height <= 400px) {
  body {
    overflow: hidden;
  }

  body .navbar-3 {
    display: none;
  }

  .horizontal-container {
    display: flex !important;
  }
}

.wrapper---newsletter .mktoForm#mktoForm_2192 .mktoLabel, .wrapper---newsletter .mktoForm#mktoForm_2510 .mktoLabel {
  width: 100% !important;
  margin-bottom: 80px !important;
}

.wrapper---newsletter .mktoForm#mktoForm_2192, .wrapper---newsletter .mktoForm#mktoForm_2510 {
  position: relative;
  width: 100% !important;
}

.wrapper---newsletter .mktoForm#mktoForm_2192 input::placeholder, .wrapper---newsletter .mktoForm#mktoForm_2510 input::placeholder {
  opacity: 1;
}

.wrapper---newsletter .mktoForm#mktoForm_2192 .mktoButtonRow, .wrapper---newsletter .mktoForm#mktoForm_2510 .mktoButtonRow {
  position: absolute;
  bottom: 10px;
  right: 0;
}

.wrapper---newsletter .mktoForm#mktoForm_2192 .mktoButtonRow .mktoButton, .wrapper---newsletter .mktoForm#mktoForm_2510 .mktoButtonRow .mktoButton {
  white-space: nowrap;
}

.wrapper---newsletter .mktoForm#mktoForm_2192 .mktoButtonRow .mktoButton:hover, .wrapper---newsletter .mktoForm#mktoForm_2510 .mktoButtonRow .mktoButton:hover {
  transition: background .2s;
  color: var(--red) !important;
  background: #fff !important;
}

.wrapper---newsletter .mktoForm#mktoForm_2192 .mktoFieldDescriptor, .wrapper---newsletter .mktoForm#mktoForm_2510 .mktoFieldDescriptor {
  margin-bottom: 0 !important;
}

.wrapper---newsletter .mktoForm#mktoForm_2192 .mktoLabel, .wrapper---newsletter .mktoForm#mktoForm_2510 .mktoLabel {
  width: 60% !important;
  margin: 20px 0 !important;
}

@media screen and (width <= 479px) {
  .wrapper---newsletter .mktoForm#mktoForm_2192 .mktoLabel, .wrapper---newsletter .mktoForm#mktoForm_2510 .mktoLabel {
    width: 100% !important;
  }

  .wrapper---newsletter .mktoForm#mktoForm_2192 #LblDisclaimer_data_privacy__c, .wrapper---newsletter .mktoForm#mktoForm_2510 #LblDisclaimer_data_privacy__c {
    width: 100% !important;
    margin-bottom: 80px !important;
  }

  .wrapper---newsletter .mktoForm#mktoForm_2192 .mktoButtonRow, .wrapper---newsletter .mktoForm#mktoForm_2510 .mktoButtonRow {
    bottom: 0;
    right: 50%;
    transform: translateX(50%);
  }
}

.wrapper-footer .mktoForm {
  position: relative;
  width: 100% !important;
}

.wrapper-footer .mktoForm input::placeholder {
  opacity: 1;
  color: #fff !important;
}

.wrapper-footer .mktoForm .mktoButtonRow {
  position: absolute;
  bottom: 20px;
  right: 0;
}

.wrapper-footer .mktoForm .mktoLabel {
  color: #fff !important;
  width: 50% !important;
}

.wrapper-footer .mktoForm .mktoEmailField {
  color: #fff !important;
  border-bottom: 1px solid #fff !important;
}

.wrapper-footer .mktoForm .mktoButton {
  color: #fff;
  white-space: nowrap;
  background: none !important;
  border: 1px solid #fff !important;
}

.wrapper-footer .mktoForm .mktoButton:hover {
  transition: background .2s;
  color: #000 !important;
  background: #fff !important;
}

.wrapper-footer .mktoForm .mchNoDecorate {
  color: #fff !important;
}

@media screen and (width <= 479px) {
  .wrapper-footer .mktoForm .mktoLabel {
    width: 100% !important;
  }

  .wrapper-footer .mktoForm #LblDisclaimer_data_privacy__c {
    width: 100% !important;
    margin-top: 10px !important;
    margin-bottom: 80px !important;
  }

  .wrapper-footer .mktoForm .mktoButtonRow {
    bottom: 0;
    right: 50%;
    transform: translateX(50%);
  }
}

.container---1200px[no-bg-color="true"] a {
  color: #fff !important;
}

.container---1200px[no-bg-color="true"] .primary-cta:hover {
  color: #ff6050 !important;
}

.wrapper-row---frise a {
  color: #fff;
  font-family: futura-pt, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  text-decoration: underline;
}

.wrapper-row---frise h3, .wrapper-row---frise h3 strong {
  font-weight: 700 !important;
}

.wrapper-row---frise h3 a {
  font-size: 24px;
  text-decoration: underline;
  font-weight: 700 !important;
}

.wrapper-articles, .wrapper-articles-2, .wrapper-articles-3 {
  overflow: visible;
}

.wrapper-articles .wrapper-table, .wrapper-articles-2 .wrapper-table, .wrapper-articles-3 .wrapper-table {
  padding-top: 80px;
}

.wrapper-articles .wrapper-table .tag-table, .wrapper-articles-2 .wrapper-table .tag-table, .wrapper-articles-3 .wrapper-table .tag-table {
  color: #00000080;
  border-radius: 20px;
  padding: 10px;
  justify-content: flex-start !important;
  width: 400px !important;
}

.wrapper-articles .wrapper-table .tag-table.active, .wrapper-articles-2 .wrapper-table .tag-table.active, .wrapper-articles-3 .wrapper-table .tag-table.active {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
  pointer-events: auto !important;
  color: #fff !important;
  background-color: #000 !important;
}

.wrapper-articles .wrapper-table .tag-table:focus, .wrapper-articles-2 .wrapper-table .tag-table:focus, .wrapper-articles-3 .wrapper-table .tag-table:focus {
  color: #00000080;
  background-color: #0000;
}

.cta.small-text---demi.little-secondary-cta.essayer {
  color: #fff;
  border: 1px solid #0000;
}
/*# sourceMappingURL=style.css.map */
