.redirection-button {
    position: relative;
    overflow: hidden;
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0;
        transform: scale(0.7);
        transition: opacity 0.4s cubic-bezier(0.25, 1, 0.5, 1), transform 0.4s cubic-bezier(0.25, 1, 0.5, 1);
        border-radius: 40px;
        z-index: 0;
    }
    .small-text---book {
        z-index: 1;
        position: relative;
        transition-delay: 0.1s;
        transition: color 0.4s cubic-bezier(0.25, 1, 0.5, 1), opacity 0.4s cubic-bezier(0.25, 1, 0.5, 1);
    }
    .icon---button-redirection {
        z-index: 1;
        position: relative;
        transition-delay: 0.1s;
        transition: filter 0.4s cubic-bezier(0.25, 1, 0.5, 1);
    }
    &:hover {
        &:after {
            opacity: 1;
            transform: scale(1.1);
        }
        .small-text---book {
           color: #fff;
           opacity: 1;
        }
        .icon---button-redirection {
            filter: invert(1);
        }
    }
}