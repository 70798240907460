.gob{
  position: relative;

  .left_col---gob{
    position: relative;
    z-index: 1;
  }
}

.gob---section{
  position: relative;
}

.gob:nth-of-type(2) {
  position: absolute;
  top: 0;
}