.frise {
  @media screen and (max-width: 768px) {
    user-select: none;
    flex-wrap: nowrap;
    overflow-x: scroll;
    justify-content: flex-start;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
