.search-input {
    outline: none;
    transition: background-color 0.4s cubic-bezier(0.25, 1, 0.5, 1);
    ~ img {
      transition: filter 0.4s cubic-bezier(0.25, 1, 0.5, 1);
    }
    &:focus {
      border-color: transparent;
      background-color: rgba(0, 0, 0, 0.8);
      ~ img {
        filter: invert(1);
      }
    }
  
  }