.nav-menu-2 {
  @media screen and (max-width: 991px) {
    padding-bottom: 100px;

    .nav-link.w-dropdown-toggle {
      pointer-events: none;

      a {
        pointer-events: auto;
      }
    }
  }

  .nav-link.w-dropdown-toggle.w--current {
    background-color: #000;
    color: #fff;
  
    @media screen and (max-width: 478px) {
      border-radius: 0;
    }
  
    a {
      color: #fff;
    }
  }
}


.nav-link.btn-nav{
  margin: 0px;
  height: fit-content;
}

.navbar-3{
  .primary-cta-wrapper{
    height: fit-content;
  }
  .div-block-38{
    align-items: center;
  }
}