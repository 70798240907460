  .horizontal-container {
    display: none;
    width: 100dvw;
    height: 100dvh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: black;
    color: white;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    &-title{
      font-size: 45px;
      margin-bottom: 24px;
    }
  }

  @media screen and (max-width: 900px) and (max-height: 400px) {
    body{
      overflow: hidden;
      .navbar-3 {
        display: none;
      }
    }
    .horizontal-container {
      display: flex!important;
    }
  }
