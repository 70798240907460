.wrapper-articles,
.wrapper-articles-2,
.wrapper-articles-3 {
  overflow: visible;

  .wrapper-table {
    padding-top: 80px;

    .tag-table {
      border-radius: 20px;
      color: rgba(0, 0, 0, .5);;
      padding: 10px;
      width: 400px !important;
      justify-content: flex-start !important;
    }

    .tag-table.active {
      position: relative;
      pointer-events: auto !important;
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
      background-color: black !important;
      color: white !important;
    }

    .tag-table:focus {
      color: rgba(0, 0, 0, .5);
      background-color: transparent;
    }
  }
}