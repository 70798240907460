.faq---section {
    .row-faq {
        user-select: none;
        &.active {
            .question-faq, .reponse-faq {
                color: #000;
            }
        }

        .top-row---faq{
            cursor: pointer;
        }

        @media screen and (min-width: 992px) {
            &:active, &:hover{
                .question-faq, .reponse-faq {
                    color: #000;
                }
            }
        }

        @media screen and (max-width: 768px) {
            transform: translateX(0);
            font-size: 40px;
        }

        .right-content-row-faq {
            border-bottom: none !important;
            position: relative;

            .border-faq {
                background-color:rgba(0, 0, 0, .5);
                height: 1px;
                width: 100%;
                transform-origin: 100% 50%;
                transform: scaleX(100%);
                position: absolute;
                bottom: 0;
            }
        }

        .reponse-faq {
            border-top: none !important;
            transition: border 0.25s cubic-bezier(0.16, 1, 0.3, 1);
        }

        &.active {
            .reponse-faq {
                border-top: 1px solid rgba(0, 0, 0, 0.5);
            }

            .num-ro-faq-text {
                font-size: 90px;
                color: #000;
                transform: translateX(70px);

                @media screen and (max-width: 768px) {
                    transform: translateX(0);
                    font-size: 40px;
                }
            }
        }
    }

    .rl_faq6_answer {
        height: auto;
    }

    .rl_faq6_icon-wrapper{
        display: none;
    }

    .num-ro-faq-text {
        transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1);
    }

    .question-faq,
    .reponse-faq {
        transition: color 0.5s cubic-bezier(0.16, 1, 0.3, 1);
    }
}