.primary-cta {
  overflow: hidden;
  position: relative;
  display: inline-block;
  margin-bottom: 30px;
  transition: inherit !important;
  border-radius: 30px !important;
}

.primary-cta_circle {
  background-color: #ff6050;
  border-radius: 1000px;
  position: absolute;
  left: 0;
  top: 0;
  width: 0px;
  height: 0px;
  margin-left: 0px;
  margin-top: 0px;
  pointer-events: none;
}

.button_text_container {
  position: relative;
  z-index: 10000;
}

.primary-cta-wrapper {
  overflow: hidden;
  position: relative;
  display: inline-block;
  background-color: rgb(0, 0, 0);
  border-radius: 50px !important;
}