.div-thank-you {
  min-height: 1060px;
  position: relative;
  margin-top: 0 !important;
  padding-top: 160px;

  .columns-calendly{
    justify-content: center;
  }

  .calendly-1,
  .calendly-2 {
    min-height: 620px;
    margin-top: -1px;
    .calendly-inline-widget{
      display: none;
    }
  }

  .loader {
    bottom: 0;
    left: 47%;
  }
}