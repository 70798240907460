.w-editor-edit-fade-in {
  .container---1200px {
    .wrapper-texte---text-image {

      .h-30px---serif,
      .primary-cta-wrapper,
      .w-richtext,
      .body---book,
      a {
        opacity: 1;
      }
    }

    .content-image---text-image img {
      opacity: 1;
    }
  }

  .hero---middle-content {
    .container-anim---header {

      transform: rotate(-10deg);

      .wrapper-hero img {
        opacity: 1;
      }
    }

    .container-anim---header-sketch {
      opacity: 1;
    }

  }

  .hero-team---section {

    .h-36px---serif,
    .body---book,
    .wrapper-image---hero-team {
      opacity: 1;
    }
  }

  .hero---section {
    .title---hero {
      opacity: 1;
    }

    .body---book {
      opacity: 1;
    }

    .hero---bottom-content a {
      opacity: 1;
    }
  }

  .hero-top-bot {
    .h-30px---serif,
    .image-tb,
    .body---book {
      opacity: 1;
    }
  }

  // default card value
  .card---price-hero {
    opacity: 1;
    scale: 1;
    transform: translateY(0);
  }

  .hero-price---section .h-30px---serif {
    opacity: 1;
  }
}