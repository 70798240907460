.container---1200px[no-bg-color=true] {
  a {
    color: white !important;
  }

  .primary-cta {
    &:hover {
      color: #ff6050 !important;
    }
  }
}

.wrapper-row---frise {
  a {
    color: white;
    font-family: futura-pt,sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    text-decoration: underline;
  }

  h3 {
    font-weight: 700 !important;

    strong {
      font-weight: 700 !important;
    }

    a {
      font-weight: 700 !important;
      text-decoration: underline;
      font-size: 24px;
    }
  }
}