.custom-cursor{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: var(--red);
  position: fixed;
  z-index: 9999;
  pointer-events: none;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: none;

  &:after {
    content: 'Drag';
    font-weight: 450;
    font-size: 16px;
    color: white;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}