.wrapper---newsletter .mktoForm#mktoForm_2192 .mktoLabel, .wrapper---newsletter .mktoForm#mktoForm_2510 .mktoLabel {
  margin-bottom: 80px !important;
  width: 100% !important;
}

.wrapper---newsletter {
  .mktoForm#mktoForm_2192, .mktoForm#mktoForm_2510 {
    position: relative;
    width: 100% !important;

    input::placeholder {
      opacity: 1;
    }

    .mktoButtonRow {
      position: absolute;
      bottom: 10px;
      right: 0;

      .mktoButton {
        white-space: nowrap;
        &:hover {
          background: white !important;
          color: var(--red) !important;
          transition: background 0.2s;
        }
      }
    }

    .mktoFieldDescriptor {
      margin-bottom: 0 !important;
    }

    .mktoLabel {
      width: 60% !important;
      margin: 20px 0 !important;
    }

    @media screen and (max-width: 479px) {
      .mktoLabel {
        width: 100% !important;
      }

      #LblDisclaimer_data_privacy__c {
        margin-bottom: 80px !important;
        width: 100% !important;
      }
    }
  }

  .mktoForm#mktoForm_2192 {
    @media screen and (max-width: 479px) {
      .mktoButtonRow {
        bottom: 0;
        right: 50%;
        transform: translateX(50%);
      }
    }
  }

  .mktoForm#mktoForm_2510 {
    @media screen and (max-width: 479px) {
      .mktoButtonRow {
        bottom: 0;
        right: 50%;
        transform: translateX(50%);
      }
    }
  }
}

.wrapper-footer {
  .mktoForm {
    position: relative;
    width: 100% !important;

    input::placeholder {
      opacity: 1;
      color: white !important;
    }

    .mktoButtonRow {
      position: absolute;
      bottom: 20px;
      right: 0;
    }

    .mktoLabel {
      color: white !important;
      width: 50% !important;
    }

    .mktoEmailField {
      border-bottom: 1px solid white !important;
      color: white !important;
    }

    .mktoButton {
      background: transparent !important;
      border: 1px solid white !important;
      color: white;
      white-space: nowrap;

      &:hover {
        background: white !important;
        color: black !important;
        transition: background 0.2s;
      }
    }

    .mchNoDecorate {
      color: white !important;
    }

    @media screen and (max-width: 479px) {
      .mktoLabel {
        width: 100% !important;
      }

      #LblDisclaimer_data_privacy__c {
        margin-bottom: 80px !important;
        margin-top: 10px !important;
        width: 100% !important;
      }

      .mktoButtonRow {
        bottom: 0;
        right: 50%;
        transform: translateX(50%);
      }
    }
  }
}
