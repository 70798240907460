.hero-top-bot {
  .box-image-content-hero-tb {
    @media screen and (max-width: 479px) {
      img {
        max-width: 100% !important;
      }
    }
  }

  .image-61 {
    bottom: -175px;
  }
}
