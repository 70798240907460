.hero---top-content,
.hero-top-bot {

  .title---hero,
  h1.h-30px---serif {
    opacity: 0;
  }

  .container-body---hero {
    opacity: 0;
    animation: fadeIn 0.7s 0.5s forwards;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}


.hero---middle-content {
  .container-anim---header {

    transform: rotate(0);

    .wrapper-hero img {
      opacity: 0;
    }
  }

  .container-anim---header-sketch {
    opacity: 0;
  }

}

[hero-mode~="true"] {
  .wrapper-texte---text-image {
    opacity: 0;
    transform: translate(5px, 10px);
    animation: fade-in 0.8s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards;
    animation-delay: 0.3s;
  }

  .h-30px---serif {
    opacity: 0;
    animation: fade-in 0.8s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards;
    animation-delay: 0.4s;
  }

  .w-richtext,
  .body---book {
    opacity: 0;
    animation: fade-in-staggered 0.8s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards;
    animation-delay: 0.45s;
  }

  .content-image---text-image img,
  .div-block-44 {
    opacity: 0;
    animation: fade-in 0.8s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards;
    animation-delay: 0.3s;
  }

  .primary-cta-wrapper,
  a {
    opacity: 0;
    animation: fade-in 0.8s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards;
    animation-delay: 0.2s;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translate(5px, 10px);
  }

  to {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes fade-in-staggered {
  from {
    opacity: 0;
    transform: translate(5px, 10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.hero-top-bot {

  .h-30px---serif,
  .image-tb,
  .body---book {
    opacity: 0;
  }
}