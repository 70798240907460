.re {
  .w-tab-content {
    overflow: visible;
  }
}

.search-input-2 {
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #000 inset !important;
    -webkit-text-fill-color: #fff;
  }
}