.wrapper---hero-price {
  overflow: visible !important;
}

.link-block-22 .ressource-card-2,
.link-block-card .card---e-book-2,
.link-block-23 .ressource-card-3,
.link-block-card .card-latest-article,
.card---e-book,
.master-card,
.ressource-card-2,
.w-dyn-item .w-layout-grid.grid-13,
.card---e-book-2,
.card-blog,
.ressource-card-3,
.card-blog,
.hero-success-story-premiere-page,
.header-blog-section-3 .w-dyn-item .w-layout-grid,
.big-header-contenu {
  transition: 0.3s ease-in-out;

  &:hover {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
    transform: scale(0.98);
    transition: 0.3s ease-in-out;
  }
}

.card-latest-article img {
  height: 159.2px;
}